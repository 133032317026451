import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, CssBaseline, Box, Typography, Button, Paper, Link } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';
import { mbarkLogo, mbarkText, screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6, googlePlayBadge, appStoreBadge } from './assets';
import './App.css';

const carouselScreenshots = [screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6];

const theme = createTheme({
  palette: {
    primary: {
      main: '#0A506D',
    },
    secondary: {
      main: '#F57E20',
    },
    background: {
      default: '#F5F5F5',
    },
    text: {
      primary: '#0A506D',
      secondary: '#F57E20',
    },
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    h2: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 600,
    },
    body1: {
      color: '#0A506D',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          color: '#0A506D',
          backgroundColor: '#F5F5F5',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
  },
});

const App = () => {
  const { ref: featuresRef, inView: featuresInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: carouselRef, inView: carouselInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: testimonialsRef, inView: testimonialsInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: videoRef, inView: videoInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: instagramRef, inView: instagramInView } = useInView({ triggerOnce: false, delay: 150 });
  const { ref: downloadRef, inView: downloadInView } = useInView({ triggerOnce: false, delay: 150 });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '0',
        },
      },
    ],
  };

  const getAppStoreLink = () => {
    const userAgent = navigator.userAgent || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return {
        link: "https://apps.apple.com/app/mbark/id6547869095",
        text: "Download on the App Store"
      };
    }

    if (/android/i.test(userAgent)) {
      return {
        link: "https://play.google.com/store/apps/details?id=com.mbark.app",
        text: "Get it on Google Play"
      };
    }

    return null;

    /*
    return {
      link: "https://app-mbark.azurewebsites.net/",
      text: "Continue to the Web App"
    };
    */
  };

  const appStore = getAppStoreLink();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app-container">
        <Container maxWidth="lg">
          <Box component="header" className="header">
            <img src={mbarkLogo} alt="mBark Logo" className="logo" />
            <img src={mbarkText} alt="mBark" className="text-logo" />
            <Typography variant="h6" className="subtitle" sx={{ color: '#F57E20', mt: 1 }}>Unleash the best spots, wherever you are!</Typography>
          </Box>

          <Box component="section" id="features" ref={featuresRef} className={`section ${featuresInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>Why mBark?</Typography>
              <ul className="features-list">
                <li><Typography variant="body1">Find Dog-Friendly Locations: Easily discover parks, cafes, restaurants, and trails that welcome dogs.</Typography></li>
                <li><Typography variant="body1">Leave Reviews: Share your experiences and read reviews from other dog owners.</Typography></li>
                <li><Typography variant="body1">Check-In: Check-in at locations to let your friends and fellow dog lovers know where you've been.</Typography></li>
                <li><Typography variant="body1">Favorite Places: Save your favorite locations for quick access.</Typography></li>
                <li><Typography variant="body1">Connect with Others: Meet and connect with other dog owners in your area.</Typography></li>
                <li><Typography variant="body1">Earn Points: Participate in activities and earn points for your engagement.</Typography></li>
              </ul>
              <Typography variant="body1" sx={{ mt: 2 }}>With mBark, you can enjoy more adventures with your dog, connect with the community, and discover new favorite spots. Download now and start exploring!</Typography>
            </Paper>
          </Box>

          <Box component="section" id="carousel" ref={carouselRef} className={`section ${carouselInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>Explore Our App</Typography>
              <Slider {...settings}>
                {carouselScreenshots.map((src, index) => (
                  <div key={index} className="carousel-slide">
                    <img src={src} alt={`Slide ${index + 1}`} style={{ width: '100%', borderRadius: '8px' }} />
                  </div>
                ))}
              </Slider>
            </Paper>
          </Box>

          <Box component="section" id="video-tutorial" ref={videoRef} className={`section ${videoInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>How It Works</Typography>
              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/e8I23OVZ7gc"
                  title="Welcome to mBark Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </Paper>
          </Box>

          <Box component="section" id="instagram-feed" ref={instagramRef} className={`section ${instagramInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>Follow Us on Instagram</Typography>
              <div className="instagram-feed-container">
                <iframe
                  src="https://www.instagram.com/letsmbark/embed"
                  title="mBark IG Feed"
                  style={{ border: 'none', overflow: 'hidden' }}
                ></iframe>
              </div>
            </Paper>
          </Box>

          <Box component="section" id="download-app" ref={downloadRef} className={`section ${downloadInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>Download the mBark App</Typography>
              <Box className="download-buttons">
                <a href="https://apps.apple.com/app/mbark/id6547869095" target="_blank" rel="noopener noreferrer" className="store-link">
                  <img src={appStoreBadge} alt="Download on the App Store" className="store-badge" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mbark.app" target="_blank" rel="noopener noreferrer" className="store-link">
                  <img src={googlePlayBadge} alt="Get it on Google Play" className="store-badge" />
                </a>
              </Box>
            </Paper>
          </Box>


          <Box component="section" id="testimonials" ref={testimonialsRef} className={`section ${testimonialsInView ? 'in-view' : ''}`}>
            <Paper className="paper">
              <Typography variant="h4" component="div" gutterBottom>User Testimonials</Typography>
              <Box>
                <Typography variant="body1" sx={{ mb: 2 }}><b>Amazing App for Dog Owners</b> - "mBark has made it so easy to find dog-friendly places. My dog and I love exploring new parks and cafes."</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}><b>Highly Recommend</b> - "This app has been a game-changer. We can now plan our outings without worrying about whether the place is dog-friendly."</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}><b>Connect with the Community</b> - "mBark not only helps us find great places but also connect with other dog owners. It's fantastic!"</Typography>
              </Box>
            </Paper>
          </Box>
        </Container>
        <Box component="footer" className="footer">
          <Typography variant="body1" sx={{ color: 'white' }}>Need help?<br />Email us at support@letsmbark.com</Typography>
          <Box sx={{ mt: 2 }}>
            <Link href="https://www.instagram.com/letsmbark/" color="inherit" target="_blank" rel="noopener">Instagram</Link>&nbsp;|&nbsp;
            <Link href="https://www.linkedin.com/in/letsmbark" color="inherit" target="_blank" rel="noopener">LinkedIn</Link>&nbsp;|&nbsp;
            <Link href="https://www.facebook.com/people/MBARK/61562847635038/" color="inherit" target="_blank" rel="noopener">Facebook</Link>
          </Box>
        </Box>
        {appStore && (
          <Button
            variant="contained"
            color="primary"
            href={appStore.link}
            sx={{
              position: 'fixed',
              bottom: '20px',
              width: '80%',
              maxWidth: '400px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: '#F57E20',
              color: '#fff',
              padding: '8px 12px',
              border: '1px solid #0A506D',
              borderRadius: '20px',
              fontSize: '1rem',
              fontWeight: 'bold',
              textAlign: 'center',
              textDecoration: 'none',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
              transition: 'background-color 0.3s ease',
              zIndex: 1000,
              '&:hover': {
                backgroundColor: '#fff',
                color: '#F57E20'
              }
            }}
          >
            {appStore.text}
          </Button>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
